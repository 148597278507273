import React, { useState, useRef } from 'react';
import './Carousel.css';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const [prevTranslate, setPrevTranslate] = useState(0);
  const trackRef = useRef<HTMLDivElement | null>(null);

  const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  const itemWidth = 600;  // Adjust for two-column width

  const getPositionX = (e: MouseEvent | TouchEvent): number => {
    return e.type.includes('mouse') ? (e as MouseEvent).pageX : (e as TouchEvent).touches[0].clientX;
  };

  const handleTouchStart = (e: React.TouchEvent) => startDrag(getPositionX(e.nativeEvent));
  const handleMouseDown = (e: React.MouseEvent) => startDrag(getPositionX(e.nativeEvent));

  const startDrag = (posX: number) => {
    setIsDragging(true);
    setStartPos(posX);
    setPrevTranslate(currentTranslate);
    if (trackRef.current) {
      trackRef.current.style.transition = 'none';
    }
  };

  const handleTouchEnd = () => endDrag();
  const handleMouseUp = () => endDrag();

  const endDrag = () => {
    setIsDragging(false);
    const movedBy = currentTranslate - prevTranslate;

    if (movedBy < -100 && currentIndex < items.length - 1) setCurrentIndex(currentIndex + 1);
    if (movedBy > 100 && currentIndex > 0) setCurrentIndex(currentIndex - 1);

    setPositionByIndex();
    if (trackRef.current) {
      trackRef.current.style.transition = 'transform 0.3s ease';
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => dragMove(getPositionX(e.nativeEvent));
  const handleMouseMove = (e: React.MouseEvent) => dragMove(getPositionX(e.nativeEvent));

  const dragMove = (posX: number) => {
    if (isDragging) {
      const currentPosition = posX;
      setCurrentTranslate(prevTranslate + currentPosition - startPos);
    }
  };

  const setPositionByIndex = () => {
    const maxTranslate = (items.length - 1) * -itemWidth;
    const clampedTranslate = Math.max(Math.min(currentIndex * -itemWidth, 0), maxTranslate);

    setCurrentTranslate(clampedTranslate);
    setPrevTranslate(clampedTranslate);
    
    if (trackRef.current) {
      trackRef.current.style.transform = `translateX(${clampedTranslate}px)`;
    }
  };

  return (
    <div className="carousel-container">
      <div 
        className="carousel-track" 
        ref={trackRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        style={{ transform: `translateX(${currentTranslate}px)` }}
      >
        {items.map((item, index) => (
          <div className="carousel-item" key={index}>
            <div className="carousel-column">
              <p>SOON</p>
              <p>SOON</p>
              <p>SOON</p>
              <p>SOON</p>
              <p>SOON</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
